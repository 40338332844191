import { FunctionalComponent, h } from "preact";
import { Ref, useEffect, useMemo, useRef, useState } from "preact/hooks";
import { Route, Router } from "preact-router";

import Home from "@/routes/home";
import Notfound from "@/routes/notfound";

import style from "./app.css";

const themes = [
  style.theme_red,
  style.theme_purple,
  style.theme_blue,
  style.theme_green,
  style.theme_yellow,
];

const useRandomTheme = () => {
  const [index] = useState(() => Math.floor(Math.random() * 10));
  const rawTheme = themes[index % themes.length];

  // Add a rebuild that runs right after hydration, to force the
  // theme to take effect, even for pre-rendered files
  const [counter, setCounter] = useState(1);
  useEffect(() => setCounter((v) => v + 1), [setCounter]);

  return [`${rawTheme} _${counter}`, rawTheme];
};

const useMetaTag = (tagName: string, content: string | null) => {
  useEffect(() => {
    const tag = document.querySelector<HTMLMetaElement>(
      `meta[name=${tagName}]`
    );

    if (tag) {
      if (content) {
        tag.setAttribute("content", content);
      } else {
        tag.remove();
      }
    } else if (content) {
      const newTag = document.createElement("meta");
      newTag.name = tagName;
      newTag.content = content;
      document.head.appendChild(newTag);
    }
  }, [tagName, content]);
};

function getAccentFromTheme(theme: string) {
  return useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }

    const element = document.createElement("div");
    element.className = theme;
    document.body.appendChild(element);

    return window.getComputedStyle(element).getPropertyValue("--accent");
  }, [theme]);
}

const App: FunctionalComponent = () => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [theme, rawTheme] = useRandomTheme();

  const accent = getAccentFromTheme(rawTheme);

  useMetaTag("theme-color", accent);

  return (
    <div id="preact_root" ref={rootRef} class={theme}>
      <Router>
        <Route path="/" component={Home} />
        <Notfound default />
      </Router>
    </div>
  );
};

export default App;
